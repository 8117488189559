import React from "react";
import MapView from "./componentes/MapView.js";
import SelectList from "./componentes/SelectList.js";
import Nav from "./componentes/Nav.js";
import Footer from "./componentes/Footer.js";
import Section from "./componentes/Section.js";
import "./App.css";



function App() {
  return (
    <div>
      <div className="container-fluid">
        <Nav></Nav>
        <Section
          title="Mapa de servicios para la atención a la violencia contra las mujeres"
          subtitle1="En este mapa podras visualizar los servicios que la Ciudad de México te ofrece. Utiliza los filtros para ubicar los servicios más cercanos a ti"
        ></Section>
        <div className="row mt-3 p-2 bg-gris-cdmx">
          <SelectList prueba="hola" />
        </div>
      </div>
      <div className="mt-3">
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
