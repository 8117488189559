import React, { useState, useEffect, useRef } from "react";
import MapView from "./MapView.js";
import "./SelectList.css";

function SelectList() {
  const [instancias, setInstancias] = useState([]);
  const [tipoSedes, setTipoSedes] = useState([]);
  const [alcaldias, setAlcaldias] = useState([]);
  const [tipoServicios, setTipoServicios] = useState([]);
  const [servicios, setServicios] = useState([]);

  const [datosMapa, setDatosMapa] = useState([]);
  const [selectedInstancia, setInstanciaSelect] = useState(0);
  const [selectedTipoServicio, setTipoServiciosSelect] = useState(0);

  const instanciasRef = useRef();
  const tipoSedesRef = useRef();
  const alcaldiasRef = useRef();
  const tipoServiciosRef = useRef();
  const serviciosRef = useRef();

  function submitHandler(event) {
    event.preventDefault();

    const enteredInstancia = instanciasRef.current.value;
    const enteredTipoSedes = tipoSedesRef.current.value;
    const enteredAlcaldias = alcaldiasRef.current.value;
    const enteredTipoServicios = tipoServiciosRef.current.value;
    const enteredServicios = serviciosRef.current.value;

    const filtrosData = {
      instancia: enteredInstancia,
      tipoSede: enteredTipoSedes,
      alcaldia: enteredAlcaldias,
      tipoServicio: enteredTipoServicios,
      servicio: enteredServicios,
    };

    setDatosMapa(filtrosData);

    //console.log("filtrosData", filtrosData);
  }

  //console.log("https://www.mapadeservicios-dev.semujeres.cdmx.gob.mx/api/tipo_sede/"+selectedInstancia,selectedInstancia);
  //      "https://www.inventariosedes.semujeres.cdmx.gob.mx/" 
  useEffect(() => {
    const urls = [
      "https://www.mapadeservicios-dev.semujeres.cdmx.gob.mx/api/instancias",
      "https://www.mapadeservicios-dev.semujeres.cdmx.gob.mx/api/tipo_sede/" +
        selectedInstancia,
      "https://www.mapadeservicios-dev.semujeres.cdmx.gob.mx/api/municipios/9",
      "https://www.mapadeservicios-dev.semujeres.cdmx.gob.mx/api/tipo_servicio",
      "https://www.mapadeservicios-dev.semujeres.cdmx.gob.mx/api/servicios/" +
        selectedTipoServicio,
    ];

    Promise.all(
      urls.map((url) =>
        fetch(url)
          .then(checkStatus)
          .then(parseJSON)
          .catch((error) =>
            console.log("se presenta el siguiente error:", error)
          )
      )
    ).then((data) => {
      const data_instancias = data[0];
      const data_tipo_sedes = data[1];
      const data_alcaldias = data[2];
      const data_tipo_servicios = data[3];
      const data_servicios = data[4];

      setInstancias(data_instancias);
      setTipoSedes(data_tipo_sedes);
      setAlcaldias(data_alcaldias);
      setTipoServicios(data_tipo_servicios);
      setServicios(data_servicios);
    });

    // ------------------------------------------
    //  HELPER FUNCTIONS
    // ------------------------------------------
    function checkStatus(response) {
      if (response.ok) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(new Error(response.statusText));
      }
    }

    function parseJSON(response) {
      return response.json();
    }
  }, [selectedInstancia, selectedTipoServicio]);

  return (
    <div>
      <form onSubmit={submitHandler}>
        <div className="row">
          <div className="form-group col-lg-2  col-md-2 col-xs-4 " >
            <label className="filterLabel">Instancias:</label>
            <select
              className="form-select filtros"
              name="instancias_id"
              id="instancias_id"
              ref={instanciasRef}
              onChange={(e) => {
                setInstanciaSelect(e.target.value);
              }}
            >
              <option value="0">Todas</option>
              {instancias.map((instancia) => (
                <option value={instancia.id} className="filtros">{instancia.descripcion.toLowerCase()}</option>
              ))}
            </select>
          </div>
          <div className="form-group col-lg-2  col-md-2 col-xs-4">
            <label className="filterLabel">Tipo de sede:</label>
            <select
              className="form-select filtros"
              name="instancias_id"
              id="instancias_id"
              ref={tipoSedesRef}
            >
              <option value="0">Todas</option>
              {tipoSedes.map((tipoSede) => (
                <option value={tipoSede.id} className="filtros">{tipoSede.descripcion.toLowerCase()}</option>
              ))}
            </select>
          </div>
          <div className="form-group col-lg-2  col-md-2 col-xs-4">
            <label className="filterLabel">Alcadías:</label>
            <select
              className="form-select filtros"
              name="municipios_id"
              id="municipios_id"
              ref={alcaldiasRef}
            >
              <option value="0">Todas</option>
              {alcaldias.map((alcaldia) => (
                <option value={alcaldia.id} className="filtros">{alcaldia.descripcion.toLowerCase()}</option>
              ))}
            </select>
          </div>
          <div className="form-group col-lg-2  col-md-2 col-xs-4">
            <label className="filterLabel">Tipo de servicio:</label>
            <select
              className="form-select filtros"
              name="tipo_servicios_id"
              id="tipo_servicios_id"
              ref={tipoServiciosRef}
              onChange={(e) => {
                setTipoServiciosSelect(e.target.value);
              }}
            >
              <option value="0">Todos</option>
              {tipoServicios.map((tipoServicio) => (
                <option value={tipoServicio.id} className="filtros">
                  {tipoServicio.descripcion.toLowerCase()}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-lg-2  col-md-2 col-xs-4">
            <label className="filterLabel">Servicio:</label>
            <select
              className="form-select filtros"
              name="tipo_servicios_id"
              id="tipo_servicios_id"
              ref={serviciosRef}
            >
              <option value="0">Todos</option>
              {servicios.map((servicio) => (
                <option value={servicio.id} className="filtros">{servicio.descripcion.toLowerCase()}</option>
              ))}
            </select>
          </div>
          <div className="col-lg-2  col-md-2 col-xs-4 mt-4">
            <div className=" col-lg-2  col-md-2 col-xs-6">
              <button type="submit" className="btn btn-cdmx ">
                Buscar
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="row ">
        <div id="map">
          <MapView datos={datosMapa} />
        </div>

      </div>
      </div>
  );
}
export default SelectList;
