import React from "react";
import "./Section.css";

function Section(props) {
  return (
    <div className="row mt-3 Section">
      <div className="col-md-12">
        <h2 className="Nav_h2">{props.title}</h2>
      </div>
      <div className="col-md-12">
        <p className="subtitle">{props.subtitle1}</p>
      </div>
    </div>
  );
}

export default Section;
