//SelectList.js
import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="container my-4">
        <div className="row justify-content-between">
          <div className="col-10 col-lg-3 mx-auto ml-md-0">
            <img className="header" src="logo_blanco.png" />
          </div>

          <div className="col-10 col-lg-4 mx-auto px-md-0">
            <h5>Atención ciudadana</h5>
            <p className="p_foooter">
              Teléfono:
              <a href="tel:5555122836"> 5555122836 </a>
            </p>
            <p className="p_foooter">
              Dirección:
              <a>
                {" "}
                Avenida Morelos 20 Colonia Centro, Alcaldía Cuauhtémoc C.P.
                06000, Ciudad de México
              </a>
            </p>
            <p className="p_foooter">
              Horarios:
              <a>
                Lunes a Jueves 9:00 a 17:00 hrs., Viernes de 9:00 a 15:00 hrs.
              </a>
            </p>
            <div id="footer-mark"></div>
          </div>
          <div className="col-10 col-lg-4 mx-auto px-md-0">
            <p className="p_foooter">
              <a href="#"> Términos y condiciones </a>
            </p>
            <p className="p_foooter">
              <a href="#"> Política de privacidad</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
