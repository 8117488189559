//SelectList.js
import React from "react";
import "./Nav.css";

function Nav(props) {
  return (
    <div className="row Nav color">
      <div className="header col-md-6">
        <img className="header" src="logo_blanco.png" />
      </div>
    </div>
  );
}

export default Nav;
